(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu-container > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.outerHeight();
  });

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  // ---------
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: false
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  $WINDOW
    .on('load', function() {
      $DOCUMENT.trigger('headerMenuEvents');
    })
    .trigger('resize');


  $DOCUMENT
    .ready(function() {

      $DOCUMENT
        .trigger('galleryRotatorImagesLoad')
        .trigger('filterPortfolio');

      $WINDOW
        .on('hashchange', function() {
          $DOCUMENT.trigger('filterPortfolio');
        })
        .on('resize', function() {
          $DOCUMENT
            .trigger('headerMenuEvents')
            .trigger('galleryRotatorImagesLoad');
        });


      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if( this.checked ) {
            $BODY.addClass('menu-is-opened');
          } else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');


      // Main rotator
      // ------------
      $('#main-rotator').slick($.extend({}, slickSettings, {
        centerMode: true,
        centerPadding: '0px',
        slide: '.main-rotator-slide-container',
        appendArrows: '#main-rotator-controls',
        draggable: true
      }));


      // Gallery rotator
      // ---------------
      $('.gallery-rotator-block-container .list-view-item').each(function() {
        var $rotator = $(this).find('.gallery-rotator'),
            $pager = $(this).find('.gallery-rotator-pager > div');

        $rotator.slick($.extend({}, slickSettings, {
          asNavFor: $pager,
          arrows: false,
          slide: '.gallery-rotator-image',
          draggable: true
        }));

        $pager.slick($.extend({}, slickSettings, {
          asNavFor: $rotator,
          centerMode: false,
          arrows: false,
          focusOnSelect: true,
          slide: '.gallery-rotator-pager-image',
          slidesToShow: 6,
          swipe: false
        }));
      });


      // Portfolio vitrine
      // -----------------
      $('.portfolio-page-block-container .portfolio-items').isotope({
        itemSelector: '.list-view-item',
        layoutMode: 'fitRows',
        percentPosition: true,
        transitionDuration: '0.5s',
        containerStyle: null
      });
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if( IS_DESKTOP_WIDTH() ) {
        if( !$HEADER_MENU.hasClass('is-desktop-events') ) {
          $WINDOW.off('scroll.headerMenuButtonPosition');

          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')

            .find('li').removeClass('is-hovered');

          // Fix header submenu popup position
          // ---------------------------------
          var headerMenuWidth = $HEADER_MENU.outerWidth();

          $HEADER_MENU.children().each(function() {
            var $item = $(this),
                $submenu = $item.children('ul').removeAttr('style');

            if( $submenu.length ) {
              $submenu.css('max-width', headerMenuWidth).find('ul').removeAttr('style');

              var position = headerMenuWidth - $submenu.outerWidth() - $item.position().left;

              if( position < 0 )
                $submenu.css('left', position);
            }
          });
        }
      }
      else {
        if( !$HEADER_MENU.hasClass('is-mobile-events') ) {
          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events')

            .find('ul').removeAttr('style');

          $WINDOW
            .on('scroll.headerMenuButtonPosition', function() {
              $DOCUMENT.trigger('headerMenuButtonPosition');
            });

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest($HEADER_MENU).length) ) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu-container > ul a, .header-menu-container > ul li > div > div > span, .header-menu-container > ul .dropmarker', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              $parent.siblings().removeClass('is-hovered').children('ul').slideUp(300);

              if( $self.prop('tagName').toUpperCase() === 'A' )
                location.href = $self.prop('href');
              else
                if( isWithDropdown ) {
                  if( isOnHover )
                      $parent.removeClass('is-hovered').children('ul').slideUp(300);
                  else
                    $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
            })

            .trigger('headerMenuButtonPosition');
        }
      }
    })

    // Menu button mobile position
    // ---------------------------
    .on('headerMenuButtonPosition', function() {
      var $button = $('.header-menu-button'),
          $container = $button.parent();

      window.headerMenuButtonTop = window.headerMenuButtonTop || $button.offset().top;

      if ($WINDOW.scrollTop() > (window.headerMenuButtonTop - parseInt($container.css('paddingTop'))))
        $container.addClass('fixed');
      else
        $container.removeClass('fixed');
    })

    // Load gallery rotator images
    // ---------------------------
    .on('galleryRotatorImagesLoad', function() {
      if( IS_LANDSCAPE_TABLET_WIDTH() ) {
        $('.gallery-rotator-block-container.gallery-items-width-4 .gallery-rotator-image img').attr('src', function() {
          return $(this).data('pre2');
        });
      }
      else {
        $('.gallery-rotator-block-container.gallery-items-width-4 .gallery-rotator-image img').attr('src', function() {
          return $(this).data('pre4');
        });
      }
    })

    // Price grouped quick links
    // --------------------------
    .on('click.priceGroupedQuickLinks', '.price-grouped-table-container .quick-link-list a', function(e) {
      e.preventDefault();

      var $link = $(this);

      if( !$link.parent().hasClass('active') ) {
        $link
          .parent().addClass('active')
          .siblings().removeClass('active')
          .first().parent().parent().addClass('selected')
          .parent().find('.price-table-container .price-table').addClass('selected')
          .find($link.attr('href').substring($link.attr('href').lastIndexOf('#')))
          .parent().addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Portfolio filter
    // ----------------
    .on('click.portfolioFilterLink', '.portfolio-page-block-container .quick-link-list a', function(e) {
      e.preventDefault();

      var filterValue = $(this).toggleClass('active').parent().children('.active').map(function() { return this.getAttribute('data-filter'); }).get().join(',');

      if( !!filterValue )
        location.hash = encodeURIComponent( filterValue );
      else
        location.hash = 'all';
    })
    .on('filterPortfolio', function() {
      if( $('.portfolio-page-block-container').length ) {
        var filterValue = decodeURIComponent( location.hash.slice(1) ) || 'all',
            $portfolioGrid = $('.portfolio-page-block-container .portfolio-items');

        $('.portfolio-page-block-container .quick-link-list a').removeClass('active').filter(filterValue.split(',').map(function(item) { return '[data-filter="'.concat(item, '"]'); }).join(',')).addClass('active');

        if( filterValue === 'all' )
          $portfolioGrid.isotope({ filter: '*'});
        else
          $portfolioGrid.isotope({ filter: filterValue.split(',').map(function(item) { return '[data-filter*="'.concat(item, '"]'); }).join(',')});
      }
    })

    // Portfolio info price item
    // -------------------------
    .on('click.portfolioInfoPriceItem', '.portfolio-info-icons .price-item > span, .portfolio-info-icons .price-stuff > span + span', function(e) {
      e.preventDefault();

      var $container = $('#portfolio-info-price-notice');

      if( !$container.html() ) {
        $.ajax({
          type: 'POST',
          url: '/ajax/render_content/',
          data: {
            ajaxRender: 'portfolioPriceNotice'
          },
          beforeSend: function() { $.mouseLoader(true); },
          dataType: 'html'
        })
          .done(function(response) {
            $.mouseLoader(false);

            $container.html(response);
            $.fancybox.open($container);
          })
          .fail(function() {
            $.mouseLoader(false);
            alert('Во время загрузки данных возникла ошибка, попробуйте позже.');
          });
      }
      else
        $.fancybox.open($container);
    })

    // Faq list answers visible toggle
    // -------------------------------
    .on('click.faqListAnswer', '.faq-list > div > div > div:first-child', function(e) {
      e.preventDefault();

      var $self = $(this).parent();

      if (!$self.hasClass('active'))
        $self.addClass('active').children('div + div').slideDown(200);
      else
        $self.removeClass('active').children('div + div').slideUp(200);
    })

    // Hide after
    // ----------
    .on('click.jsHideAfter', '.js-hide-after', function(e) {
      e.preventDefault();

      $(this).remove();
    })

    // Show-hide block
    // ---------------
    .on('click.jsShowHide', '.js-show-hide > div:first-child > span', function(e) {
      e.preventDefault();

      var $self = $(this),
          $container = $self.closest('.js-show-hide');

      if( $container.hasClass('active') )
        $self.text($container.removeClass('active').data('text'));
      else
        $self.text($container.addClass('active').data('textActive'));
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substring($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {axis: 'y', duration: speed, offset: offset});
    })

    .on('click.headerCallbackMessageLink', '.header .header-contacts .header-buttons .callback-message-button', function() {
      callCallbacks({'headerCallbackMessageButton':1});
    })

    .on('click.headerCallbackPhoneLink', '.header .header-contacts .header-buttons .callback-phone-button', function() {
      callCallbacks({'headerCallbackPhoneButton':1});
    })

    .on('click.footerCallbackPhoneLink', '.footer .footer-left-container .footer-buttons .button-style', function() {
      callCallbacks({'footerCallbackPhoneButton':1});
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: function(widget, current) {
      if( current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH() ) {
        current.$content.find('.js-autofocus-inp').trigger('focus');
      }
    }
  });
})();